import axios from 'axios'
import firebase from 'firebase'
import moment from 'moment'

export default {
    namespaced: true,
    state: {
      vehicles: [],
      vehiclesListener: null,
      cloudUploadProgress: 0,

      itemsSortBy: 'newestFirst',
      itemsPerPage: 5,
      itemsCurrentPage: 1,

      itemsFilterMake: null,
      itemsFilterModel: null,
      itemsFilterYear: null,
      itemsFilterFuel: null,
      itemsFilterMileage: null,
      itemsFilterPrice: null
    },
    getters: {
      getAllVehicles(state) {
        return state.vehicles
      },
      getActiveVehicles(state) {
        return state.vehicles.filter(v => v.active)
      },
      getInactiveVehicles(state) {
        return state.vehicles.filter(v => !v.active)
      },
      getFeaturedVehicles(state) {
        return state.vehicles.filter(v => v.featured)
      }
    },
    mutations: {
      SET_VEHICLES(state, payload) {
        state.vehicles = payload
      },
      SET_VEHICLES_LISTENER(state, payload) {
        state.vehiclesListener = payload
      },

      SET_CLOUD_UPLOAD_PROGRESS(state, payload) {
        state.cloudUploadProgress = payload
      },

      SET_ITEMS_SORT_BY(state, payload) {
        state.itemsSortBy = payload
      },
      SET_ITEMS_PER_PAGE(state, payload) {
        state.itemsPerPage = payload
      },
      SET_ITEMS_CURRENT_PAGE(state, payload) {
        state.itemsCurrentPage = payload
      },

      SET_ITEMS_FILTER_MAKE(state, payload) {
        state.itemsFilterMake = payload
      },
      SET_ITEMS_FILTER_MODEL(state, payload) {
        state.itemsFilterModel = payload
      },
      SET_ITEMS_FILTER_YEAR(state, payload) {
        state.itemsFilterYear = payload
      },
      SET_ITEMS_FILTER_FUEL(state, payload) {
        state.itemsFilterFuel = payload
      },
      SET_ITEMS_FILTER_MILEAGE(state, payload) {
        state.itemsFilterMileage = payload
      },
      SET_ITEMS_FILTER_PRICE(state, payload) {
        state.itemsFilterPrice = payload
      }
    },
    actions: {

      getVehicleDetailsFromReg({}, payload) {
        return new Promise((resolve, reject) => {
          axios({
            method: 'get',
            url: 'https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&auth_apikey=a8f97a99-c026-4e36-b7df-aa31979dc534&key_VRM=' + payload,
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            
            // Log event
            firebase.firestore().collection('logging')
            .add({
              event: 'getVehicleDetails',
              vehicleReg: payload,
              timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              user: firebase.auth().currentUser.email
            })
            .then(() => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
          })
          .catch(error => {
            reject(error)
          })
        })
      },

      getVehicleMotTaxFromReg({}, payload) {
        return new Promise((resolve, reject) => {
          axios({
            method: 'get',
            url: 'https://uk1.ukvehicledata.co.uk/api/datapackage/MotHistoryAndTaxStatusData?v=2&api_nullitems=1&auth_apikey=a8f97a99-c026-4e36-b7df-aa31979dc534&key_VRM=' + payload,
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            
            // Log event
            firebase.firestore().collection('logging')
            .add({
              event: 'getVehicleMotTax',
              vehicleReg: payload,
              timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              user: firebase.auth().currentUser.email
            })
            .then(() => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
          })
          .catch(error => {
            reject(error)
          })
        })
      },

      getVehicleValuationFromReg({}, payload) {
        return new Promise((resolve, reject) => {
          axios({
            method: 'get',
            url: 'https://uk1.ukvehicledata.co.uk/api/datapackage/ValuationData?v=2&api_nullitems=1&auth_apikey=a8f97a99-c026-4e36-b7df-aa31979dc534&key_VRM=' + payload,
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            
            // Log event
            firebase.firestore().collection('logging')
            .add({
              event: 'getVehicleValuation',
              vehicleReg: payload,
              timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              user: firebase.auth().currentUser.email
            })
            .then(() => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
          })
          .catch(error => {
            reject(error)
          })
        })
      },

      getVehicleStockImageFromReg({}, payload) {
        return new Promise((resolve, reject) => {
          axios({
            method: 'get',
            url: 'https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleImageData?v=2&api_nullitems=1&auth_apikey=a8f97a99-c026-4e36-b7df-aa31979dc534&key_VRM=' + payload,
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {

            // Log event
            firebase.firestore().collection('logging')
            .add({
              event: 'getVehicleStockImage',
              vehicleReg: payload,
              timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              user: firebase.auth().currentUser.email
            })
            .then(() => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
          })
          .catch(error => {
            reject(error)
          })
        })
      },

      getVehicleSpecOptionsFromReg({}, payload) {
        return new Promise((resolve, reject) => {
          axios({
            method: 'get',
            url: 'https://uk1.ukvehicledata.co.uk/api/datapackage/SpecAndOptionsData?v=2&api_nullitems=1&auth_apikey=a8f97a99-c026-4e36-b7df-aa31979dc534&key_VRM=' + payload,
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {

            // Log event
            firebase.firestore().collection('logging')
            .add({
              event: 'getVehicleSpecOptions',
              vehicleReg: payload,
              timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              user: firebase.auth().currentUser.email
            })
            .then(() => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
          })
          .catch(error => {
            reject(error)
          })
        })
      },

      cloudStorageUpload({ commit }, payload) {
        return new Promise((resolve, reject) => {
          commit('SET_CLOUD_UPLOAD_PROGRESS', 0)
          const storageRef = firebase.storage().ref(payload.firebaseStorageRef).put(payload.image)
          storageRef.on(`state_changed`,
            snapshot => {
              commit('SET_CLOUD_UPLOAD_PROGRESS', Math.round((snapshot.bytesTransferred/snapshot.totalBytes)*100))
            },
            error => {
              reject(error.message)
            },
            () => { commit('SET_CLOUD_UPLOAD_PROGRESS', 100)
              storageRef.snapshot.ref.getDownloadURL().then((url) => {
                const publicUrl = url.split('&token') // Remove token from public URL
                resolve(publicUrl[0])
              })
            }
          )
        })
      },
      cloudStorageDelete({}, payload) {
        return new Promise((resolve, reject) => {
          const ref = firebase.storage().ref().child(payload)
          if (ref) return resolve()
          ref.delete().then(() => {
            resolve()
          }).catch((error) => {
            reject(error)
          })
        })
      },
      dbAddVehicle({}, payload) {
        return new Promise(async (resolve, reject) => {
          firebase.firestore().collection('vehicles')
            .doc(payload.vehicle.reg + '-' + moment().format('YYYYMMDD'))
            .set(payload)
            .then(() => {

                // Log event
                firebase.firestore().collection('logging')
                  .add({
                    event: 'addStock',
                    vehicleReg: payload.vehicle.reg,
                    timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                    user: firebase.auth().currentUser.email
                  })
                  .then(() => {
                    resolve()
                  })
                  .catch(error => {
                    console.error('Logging error')
                    reject(error)
                  })
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

      dbUpdateVehicle({ }, payload) {
        return new Promise((resolve, reject) => {
          firebase.firestore().collection('vehicles')
            .doc(payload.docId)
            .set({
              active: payload.data.active,
              updated: payload.data.updated,
              featured: payload.data.featured,
              vehicle: {
                reg: payload.data.vehicle.reg,
                make: payload.data.vehicle.make,
                model: payload.data.vehicle.model,
                variant: payload.data.vehicle.variant,
                bodyType: payload.data.vehicle.bodyType,
                fuelType: payload.data.vehicle.fuelType,
                colour: payload.data.vehicle.colour,
                transmission: payload.data.vehicle.transmission,
                engine: payload.data.vehicle.engine,
                power: payload.data.vehicle.power,
                seats: payload.data.vehicle.seats,
                registrationDate: payload.data.vehicle.registrationDate,
                taxBand: payload.data.vehicle.taxBand,
                mileage: payload.data.vehicle.mileage,
                motDue: payload.data.vehicle.motDue,
                owners: payload.data.vehicle.owners
              },
              advert: {
                price: payload.data.advert.price,
                title: payload.data.advert.title,
                subtitle: payload.data.advert.subtitle,
                tag: payload.data.advert.tag,
                description: payload.data.advert.description,
                features: payload.data.advert.features,
                serviceHistory: payload.data.advert.serviceHistory,
              }
            }, {merge: true})
            .then(() => {

              // Log event
              firebase.firestore().collection('logging')
                .add({
                  event: 'editStock',
                  vehicleReg: payload.data.vehicle.reg,
                  timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                  user: firebase.auth().currentUser.email
                })
                .then(() => {
                  resolve()
                })
                .catch(error => {
                  reject(error)
                })
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      async dbDeleteVehicle({ dispatch }, payload) {
        // Delete photos from cloud storage
        if (payload.images && payload.images.length) {
          for (const image of payload.images) {
            await dispatch('cloudStorageDelete', image.storageRef)
          }
        }

        return new Promise((resolve, reject) => {
          firebase.firestore().collection('vehicles')
            .doc(payload.id)
            .delete()
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      dbUpdateVehicleImages({}, payload) {
        return new Promise((resolve, reject) => {
          firebase.firestore().collection('vehicles')
            .doc(payload.id)
            .update({
              'images': payload.images
            })
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

      dbGetAllVehicles({ commit }) {
        // let vehicles = []
        const listener = firebase.firestore().collection('vehicles')
          .onSnapshot((querySnapshot) => {
            let vehicles = []
            querySnapshot.forEach(doc => {
              let vehicle = doc.data()
              if (vehicle.vehicle) {
                vehicle.id = doc.id
                vehicles.push(vehicle)
              }
            })
            commit('SET_VEHICLES', vehicles)
          })
        commit('SET_VEHICLES_LISTENER', listener)
      },
      dbUnsubscribeVehicles({ state }) {
        if (state.vehiclesListener) state.vehiclesListener()
      },

      setItemsSortBy({ commit }, val) {
        commit('SET_ITEMS_SORT_BY', val)
      },
      setItemsPerPage({ commit }, val) {
        commit('SET_ITEMS_PER_PAGE', val)
      },
      setItemsCurrentPage({ commit }, val) {
        commit('SET_ITEMS_CURRENT_PAGE', val)
      },

      setItemsFilterMake({ commit }, val) {
        commit('SET_ITEMS_FILTER_MAKE', val)
      },
      setItemsFilterModel({ commit }, val) {
        commit('SET_ITEMS_FILTER_MODEL', val)
      },
      setItemsFilterYear({ commit }, val) {
        commit('SET_ITEMS_FILTER_YEAR', val)
      },
      setItemsFilterFuel({ commit }, val) {
        commit('SET_ITEMS_FILTER_FUEL', val)
      },
      setItemsFilterMileage({ commit }, val) {
        commit('SET_ITEMS_FILTER_MILEAGE', val)
      },
      setItemsFilterPrice({ commit }, val) {
        commit('SET_ITEMS_FILTER_PRICE', val)
      },
      logAddEvent({}, payload) {
        return new Promise(async (resolve, reject) => {
          // Log event
          firebase.firestore().collection('logging')
            .add({
              event: 'regLookup',
              vehicleReg: payload,
              timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              user: firebase.auth().currentUser.email
            })
            .then(() => {
              resolve()
            })
            .catch(error => {
              console.error('Logging error')
              reject(error)
            })
        })
      }
    },
}