import firebase from 'firebase'

export default {
  namespaced: true,
  state: {
    apiUsage: [],
  },
  getters: {
    getAllAPIUsageCalls(state, getters, rootState) {
      if (state.apiUsage.length === 0) {
          return []
      }

      if (rootState.user.user.email === 'sales@midlandautosales.co.uk') {
        
        const allCalls = state.apiUsage.filter(apiCall => {
          return apiCall.event === 'addStock' && apiCall.user === 'sales@midlandautosales.co.uk' || apiCall.event === 'valuationTool' && apiCall.user === 'sales@midlandautosales.co.uk' || apiCall.event === 'regLookup' && apiCall.user === 'sales@midlandautosales.co.uk'
        })
        
        return allCalls.sort(function (a, b) {
          return a.timestamp - b.timestamp        
        })
      } else {
          const allCalls = state.apiUsage.filter(apiCall => {
          return apiCall.event === 'addStock' || apiCall.event === 'valuationTool' || apiCall.event === 'regLookup'
        })
        
        return allCalls.sort(function (a, b) {
          return a.timestamp - b.timestamp        
        })
      }
    },
  },
  mutations: {
    SET_APIUSAGE(state, payload) {
        state.apiUsage = payload
      },
  },
  actions: {
    dbGetAPIUsage({ commit }) {
      let apiCalls = []
      firebase.firestore().collection('logging')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(doc => {
            let apiCall = doc.data()
            apiCall.id = doc.id
            if (apiCall.event === 'valuationTool') {
              apiCall.cost = 0.25
            }
            if (apiCall.event === 'regLookup') {
              apiCall.cost = 0.85
            }
            apiCalls.push(apiCall)
          })
        })
      commit('SET_APIUSAGE', apiCalls)
      },
    }
  }