import Vue from 'vue'
import VueRouter from 'vue-router'

// import Home from '../views/Home.vue'

// Authorisation logic
import AuthGuard from "./auth-guard";

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Coming Soon',
  //   meta: {
  //     requiresAuth: false, 
  //   },
  //   component: () => import('../views/ComingSoon.vue'),
  // },
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: false, 
    },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/pre-owned',
    name: 'Pre-Owned Vehicles',
    component: () => import('../views/Inventory.vue'),
    meta: {
        requiresAuth: false, 
    },
  },
  {
    path: '/cardetail/:id?',
    name: 'Car Detail',
    component: () => import('../views/CarDetail.vue'),
    meta: {
        requiresAuth: false, 
    },
  },
  {
    path: '/warranty',
    name: 'Warranty',
    component: () => import('../views/Warranty.vue'),
    meta: {
        requiresAuth: false, 
    },
  },
  {
    path: '/contactus',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
        requiresAuth: false, 
    },
  },
  {
    path: '/sellyourcar',
    name: 'Sell Your Car',
    component: () => import('../views/SellYourCar.vue'),
    meta: {
        requiresAuth: false, 
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: {
        requiresAuth: false, 
    },
  },
  {
    path: '/admin-area',
    name: 'AdminArea',
    component: () => import('../views/AdminArea.vue'),
    meta: {
        requiresAuth: true, 
    }
  },
  { path: "*", component: () => import('../views/404.vue'), }
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(AuthGuard)

export default router
