import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
import * as modules from './modules'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default new Vuex.Store({
  modules,
  plugins: [
    vuexLocal.plugin,
  ],
  state: {
  },
  mutations: {
  },
  actions: {
  }
})
