<template>
  <div id="app">
    <NavHeader />
    <transition
      name="fade"
      mode="out-in"
    >
      <RouterView :key="$route.fullPath" />
    </transition>
    <NavFooter />
  </div>
</template>

<script>
export default {
  components: {
    NavHeader: () => import('./components/Header'),
    NavFooter: () => import('./components/Footer')
  },
}
</script>

<style>
@import '../public/assets/css/bootstrap.min.css';
@import '../public/assets/css/style.css';
/*@import '../public/assets/css/owl.carousel.css';*/
@import '../public/assets/css/owl.transitions.css';
@import '../public/assets/css/slick.css';
@import '../public/assets/css/bootstrap-slider.min.css';
@import '../public/assets/css/font-awesome.min.css';
@import '../public/assets/css/UKNumberPlate.css';
@import '../public/assets/colors/red.css';

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
