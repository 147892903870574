import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase'

 // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDM-c-OzM_Dyp3WVc7BvmbfFXSJoAUQwRk",
  authDomain: "midlandautosales-c2dcf.firebaseapp.com",
  projectId: "midlandautosales-c2dcf",
  storageBucket: "midlandautosales-c2dcf.appspot.com",
  messagingSenderId: "62403987595",
  appId: "1:62403987595:web:58c8e83ef2721cedbcac8e",
  measurementId: "G-18NJBMWFPF"
};

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueSocialSharing)

Vue.mixin({
  methods: {
    thousandsSeparators(num)
      {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
      }
  }
})

Vue.config.productionTip = false

let app = null

// wait for firebase auth to init before creating the app
firebase.auth().onAuthStateChanged(() => {
  /* eslint-disable no-new */
  if (!app) {
    app = new Vue({
      router,
      store,
      created() {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            user.getIdTokenResult().then(idTokenResult => {
              store.commit('user/SET_TOKEN', idTokenResult)
            })
          }
        })
      },
      onIdle () {
        //
        if (this.$route.meta.exemptFromIdleCheck === true) {
          // keep user logged in if page is exempt from idleness check
        } else {
          firebase
            .auth()
            .signOut()
            .then(() => {
              window.localStorage.clear()
              this.$router.push({ name: 'Home' })
            })
        }
      },
      onActive () {
        // do nothing. keep user logged in
      },
      render: h => h(App),
        }).$mount('#app')
      }
})