import firebase from "firebase";

export default (to, from, next) => {
    if (to.matched.some(rec => rec.meta.requiresAuth)) {
        const user = firebase.auth().currentUser
        if (user !== null) {
            // console.log('user not null')
            next()
        } else {
            // console.log('user null')
            next({ name: "Home" })
        }
    } else {
        next()
    }
}
