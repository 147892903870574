import firebase from 'firebase'
import router from '../../router'

export default {
    namespaced: true,
    state: {
        user: null,
        token: null,
    },
    getters: {
    },
    mutations: {
        SET_USER(state, payload) {
            state.user = payload
        },
        SET_TOKEN (state, payload) {
            state.token = payload
        },
    },
    actions: {
        signUserIn({ commit }, payload) {
            console.log('logging in ')
            firebase
                .auth()
                .signInWithEmailAndPassword(payload.email, payload.password)
                .then(cred => {
                     commit('SET_USER', cred.user)
                    firebase
                        .auth()
                        .currentUser.getIdTokenResult()
                        .then(idTokenResult => {
                            commit('SET_TOKEN', idTokenResult)
                            router.push({ name: 'AdminArea' });
                        }).catch(err => {
                            console.log('err:', err)
                        })
                })
        },
        signUserOut({ commit }) {
            firebase
            .auth()
            .signOut()
            .then(() => {
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
                router.push({ name: 'Admin' });
            })
            .catch(error => {
                console.log('error: ', error)
                router.push({ name: 'Admin' });
            });
        },
        checkLoggedIn({ commit }) {
            if (firebase.auth().currentUser) {
                commit('SET_USER', firebase.auth().currentUser)
                router.push({ name: 'AdminArea' }).catch(()=>{})
            } else {
                router.push({ name: 'Admin' }).catch(()=>{})
            }
        }
    },
}
